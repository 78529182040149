import GlobalStyle from "./GlobalStyle";
import StateProvider from "./StateProvider";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Language from "./components/Language/Language";

import Burgery from "./components/Burgery/Burgery";
import Wypasik from "./components/Wypasik/Wypasik";
import Dodatki from "./components/Dodatki/Dodatki";
import Doladowanie from "./components/Doladowanie/Doladowanie";
import Zestawy from "./components/Zestawy/Zestawy";
import Boxy from "./components/Boxy/Boxy";
import Salatki from "./components/Salatki/Salatki";
import Sniadanko from "./components/Sniadanko/Sniadanko";
import Napoje from "./components/Napoje/Napoje";
import Wlapy from "./components/Wlapy/Wlapy";
import Special from "./components/Special/Special";
import Tupak from "./components/Tupak/Tupak";
import Popup from "./components/Popup/Popup";

const App = () => {
  return (
    <StateProvider>
      <Popup />
      <GlobalStyle />
      <Language />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<Burgery />} />
          {/* <Route path="special" element={<Special />} /> */}
          <Route path="special" element={<Navigate to="/" />} />
          <Route path="2pak" element={<Tupak />} />
          <Route path="burgery" element={<Burgery />} />
          <Route path="wlapy" element={<Wlapy />} />
          <Route path="wypasik" element={<Wypasik />} />
          <Route path="dodatki" element={<Dodatki />} />
          <Route path="zestawy" element={<Zestawy />} />
          <Route path="doladowanie" element={<Doladowanie />} />
          <Route path="boxy" element={<Boxy />} />
          <Route path="salatki" element={<Salatki />} />
          <Route path="sniadanko" element={<Sniadanko />} />
          <Route path="napoje" element={<Napoje />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </StateProvider>
  );
};

export default App;
