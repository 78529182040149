import styled from "styled-components";

const Wrapper = styled.a`
display:flex;
justify-content:center ;
align-items:center ;
width:100%;
color:white;
background-color:black ;
font-family:Gotham, sans-sans-serif ;
text-transform:uppercase ;
padding:15px;
text-align:center ;
font-size:4.5vw;
text-decoration: none ;
outline:none;
cursor: pointer;
`

const BannerLink = (props) => {
    return (
        <Wrapper href={props.link ? props.link : false}>
        { props.children }
        </Wrapper>
    );
}

export default BannerLink;