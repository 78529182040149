
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import LemoIceList from "./LemoIceList"
import BannerLink from "../BannerLink/BannerLink";
import Footer from "../Footer/Footer";
import NapojeList from "./NapojeList";
import KawaList from "../Sniadanko/KawaList";

const Napoje = () => {
    return (
        <>
        <Heading>napoje</Heading>
        <MenuScroll highlight="napoje"/>
        <BannerLink link="/dodatki">+ dobierz dodatki</BannerLink>
        <LemoIceList/>
        <NapojeList/>
        <KawaList/>
        <Footer/>
        </>
    );
};

export default Napoje;