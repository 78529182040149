import styled from "styled-components";
import { special } from "../../data";
import SpecialListItem from "./SpecialListItem";
import banner from "../../images/pulled_beef_banner.png";

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100vw;
  padding: 0 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: #f0f0f0;
`;

const Jumbotron = styled.img`
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
  -moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
  box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
`;

// const HyperLink = styled.a`
// outline:0;
// border:0;
// text-decoration:none ;
// `;

const SpecialList = () => {
  return (
    <Wrapper>
      <Jumbotron
        src={
          "https://pasibus.blob.core.windows.net/media/6614/app-baner_lto3-meksyk.png?width=500&mode=max&animationprocessmode=first"
        }
      />
      {special.map((burger) => (
        <SpecialListItem burger={burger} key={burger.name} />
      ))}
    </Wrapper>
  );
};

export default SpecialList;
