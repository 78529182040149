
import BannerLink from "../BannerLink/BannerLink";
import Footer from "../Footer/Footer";
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import DoladowanieList from "./DoladowanieList";

const Doladowanie = () => {
    return (
        <>
        <Heading>Doładuj</Heading>
        <MenuScroll highlight="doładowanie"/>
        <BannerLink link="/burgery">wróć do burgerów</BannerLink>
        <DoladowanieList/>
        <Footer/>
        </>
    );
};

export default Doladowanie;