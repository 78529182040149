
import BannerLink from "../BannerLink/BannerLink";
import Footer from "../Footer/Footer";
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import WlapyList from "./WlapyList";

const Wlapy = () => {
    return (
        <>
        <Heading>włapy</Heading>
        <MenuScroll highlight="włapy"/>
        <BannerLink link="/dodatki">+ dobierz dodatki</BannerLink>
        <WlapyList/>
        <Footer/>
        </>
    );
};

export default Wlapy;