import styled from "styled-components"
import { tupak } from "../../data";
import TupakListItem from "./TupakListItem";
import tupakbaner from "../../images/tupakbaner.png"


const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:15px;
padding-bottom:15px;
background-color: #f0f0f0;

`
const Jumbotron = styled.img`
width:100%;
border-radius: 5px;
-webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
-moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
`


const TupakList = () => {
    return (
        <Wrapper>
            <Jumbotron src={tupakbaner}/>
            {tupak.map(prod=><TupakListItem burger={prod} key={prod.name}/>)}
        </Wrapper>
    );
}

export default TupakList;