import styled from "styled-components";
import popupClose from "../../../src/images/popup_close.png"
import popupClick from "../../../src/images/popup_click.png"
import { useState } from "react";

const Wrapper = styled.div`
position:fixed;
width:100vw;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`;

const Image = styled.img`
width:85%;
`;
const Image2 = styled.img`
width:100%;
`;

const Link = styled.a`
width:85%;
`;

const Popup = () => {
    const [isVisible, setIsVisible] = useState(true);
    return(
        <>
        {
            isVisible?
            <Wrapper>
                <Image src={popupClose}  onClick={()=>setIsVisible(false)} alt="popupClose"/>
                <Link href="http://pasibus.pl/pl/pasiappka" target="_blank" rel="noreferrer"  onClick={()=>{setIsVisible(false)}}><Image2 src={popupClick} alt="popupClick"/></Link>
            </Wrapper> : null
        }
        </>
    );
}

export default Popup;