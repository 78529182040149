import { useEffect } from "react";
import styled from "styled-components";
import Badge from "../Badge/Badge";
import BadgeSpecial from "../Badge/BadgeSpecial";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
  gap: 10px;
  padding: 15px 15px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MenuScroll = (props) => {
  useEffect(() => {
    document.getElementById("active").scrollIntoView({ inline: "center" });
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      {/* <BadgeSpecial
        id={props.highlight === "special" ? "active" : "inactive"}
        secondary={props.highlight === "special" ? false : true}
        href="/special"
      >
        CHILI&nbsp;CON&nbsp;CARNE
      </BadgeSpecial> */}
      <Badge
        id={props.highlight === "burgery" ? "active" : "inactive"}
        secondary={props.highlight === "burgery" ? false : true}
        href="/burgery"
      >
        burgery
      </Badge>
      <Badge
        id={props.highlight === "zestawy" ? "active" : "inactive"}
        secondary={props.highlight === "zestawy" ? false : true}
        href="/zestawy"
      >
        zestawy
      </Badge>
      <Badge
        id={props.highlight === "włapy" ? "active" : "inactive"}
        secondary={props.highlight === "włapy" ? false : true}
        href="/wlapy"
      >
        włapy
      </Badge>
      <Badge
        id={props.highlight === "wypasik" ? "active" : "inactive"}
        secondary={props.highlight === "wypasik" ? false : true}
        href="/wypasik"
      >
        wypasik
      </Badge>
      <Badge
        id={props.highlight === "boxy" ? "active" : "inactive"}
        secondary={props.highlight === "boxy" ? false : true}
        href="/boxy"
      >
        boxy
      </Badge>
      <Badge
        id={props.highlight === "2pak" ? "active" : "inactive"}
        secondary={props.highlight === "2pak" ? false : true}
        href="/2pak"
      >
        2pak
      </Badge>
      <Badge
        id={props.highlight === "dodatki" ? "active" : "inactive"}
        secondary={props.highlight === "dodatki" ? false : true}
        href="/dodatki"
      >
        dodatki
      </Badge>
      <Badge
        id={props.highlight === "salatki" ? "active" : "inactive"}
        secondary={props.highlight === "salatki" ? false : true}
        href="/salatki"
      >
        sałatki
      </Badge>
      <Badge
        id={props.highlight === "doładowanie" ? "active" : "inactive"}
        secondary={props.highlight === "doładowanie" ? false : true}
        href="/doladowanie"
      >
        doładowanie
      </Badge>
      <Badge
        id={props.highlight === "sniadanko" ? "active" : "inactive"}
        secondary={props.highlight === "sniadanko" ? false : true}
        href="sniadanko"
      >
        śniadanko
      </Badge>
      <Badge
        id={props.highlight === "napoje" ? "active" : "inactive"}
        secondary={props.highlight === "napoje" ? false : true}
        href="napoje"
      >
        napoje
      </Badge>
      <Badge secondary href="http://kupony.pasibus.pl" target="_blank">
        kupony
      </Badge>
    </Wrapper>
  );
};

export default MenuScroll;
