
import BannerLink from "../BannerLink/BannerLink";
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import Footer from "../Footer/Footer";
import BoxyList from "./BoxyList";

const Boxy = () => {
    return (
        <>
        <Heading>Boxy</Heading>
        <MenuScroll highlight="boxy"/>
        <BannerLink link="/napoje">+ dobierz napój</BannerLink>
        <BoxyList/>
        <Footer/>
        </>
    );
};

export default Boxy;