
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import TupakList from "./TupakList";
import Footer from "../Footer/Footer";

const Tupak = () => {
    return (
        <>
        <Heading>2pak</Heading>
        <MenuScroll highlight="2pak"/>
        <TupakList/>
        <Footer/>
        </>
    );
};

export default Tupak;