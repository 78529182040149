import styled from "styled-components";
import Multiline from "../Multiline/Multiline";
import pepper from "../../images/pepper.svg";

const Wrapper = styled.li`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 10px;
  gap: 5px;
  background-color: white;
  -webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
  -moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
  box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
`;
const Image = styled.img`
  width: 120px;
  height: 120px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const RowWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HotIcon = styled.img`
  height: 1.7rem;
  margin-left: 1px;
  &:first-child {
    margin-left: 10px;
  }
`;

const Ingredients = styled.p`
  font-size: 1.2rem;
  padding: 5px 3px;
`;

const Bold = styled.strong`
  font-weight: 900;
`;

const BurgerListItem = (props) => {
  return (
    <Wrapper>
      <Image src={props.burger.img} />
      <ColumnWrapper>
        <RowWrapper>
          <Multiline>{props.burger.name}</Multiline>
          {props.burger.hot
            ? [...Array(props.burger.hot)].map((e, i) => (
                <HotIcon src={pepper} key={i} />
              ))
            : false}
        </RowWrapper>
        <Ingredients>
          <Bold>{props.burger.ingredients[0]},</Bold>{" "}
          {props.burger.ingredients.slice(1).join(", ")}
        </Ingredients>
      </ColumnWrapper>
    </Wrapper>
  );
};

export default BurgerListItem;
