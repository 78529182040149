import BannerLink from "../BannerLink/BannerLink";
import Footer from "../Footer/Footer";
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import BurgerList from "./BurgerList";

const Burgery = () => {
  return (
    <>
      <Heading>burgery</Heading>
      <MenuScroll highlight="burgery" />
      <BannerLink link="/doladowanie">+ doładuj burgera</BannerLink>
      <BurgerList />
      <Footer />
    </>
  );
};

export default Burgery;
