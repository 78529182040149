import styled from "styled-components"
import { lemoIce } from "../../data";
import LemoIceListItem from "./LemoIceListItem";

const Wrapper = styled.ul`
display:flex;
flex-direction: column;
gap:10px;
width:100vw;
padding:0 15px;
padding-bottom:10px;
background-color: #f0f0f0;
padding-top:15px;
`



const SniadankoList = () => {
    return (
        <Wrapper>
            {lemoIce.map(pasik=><LemoIceListItem burger={pasik} key={pasik.name}/>)}
        </Wrapper>
    );
}

export default SniadankoList;