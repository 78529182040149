
import styled from "styled-components";
import pl from "../../images/en.png";

const Icon = styled.img`
width:30px;
margin:20px;
margin-bottom:-30px;
`;

const Wrapper = styled.div`
display:flex;
justify-content: flex-end;
`;

const Link = styled.a`
text-decoration: none;
outline:0;
border:0;
`

const Language = ()=>{
    return (
        <Wrapper>
            <Link href="https://menu-eng.pasibus.pl"><Icon src={pl}/></Link>
        </Wrapper>
    );
}

export default Language;