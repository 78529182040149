import styled from "styled-components";
import { burgery, vegan } from "../../data";
import BurgerListItem from "./BurgerListItem";
import podmien from "../../images/podmien.svg";
import zestaw from "../../images/zestaw.png";
import bulki from "../../images/wybierz_bulke.png";
import double from "../../images/double.png";
// import BurgerListItemVegan from "./BurgerListItemVegan";

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100vw;
  padding: 0 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: #f0f0f0;
`;
const Image = styled.img`
  width: 100%;
  padding: 5px;
`;
const Jumbotron = styled.img`
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
  -moz-box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
  box-shadow: 4px 4px 9px -7px rgba(84, 84, 84, 1);
`;

const Badge = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  padding: 0.2em 0.6em;
  border-radius: 2.4rem;
  margin: 10px 0;
  font-family: "Gotham", sans-serif;
  color: #62d667;
  background-color: #075117;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
`;

const BurgerList = () => {
  return (
    <Wrapper>
      <Jumbotron src={bulki} />

      <Image src={podmien} />
      <Jumbotron src={zestaw} />
      {burgery.slice(0, 5).map((burger) => (
        <BurgerListItem burger={burger} key={burger.name} />
      ))}
      <Jumbotron src={double} />
      {burgery.slice(5, 9).map((burger) => (
        <BurgerListItem burger={burger} key={burger.name} />
      ))}
      {/* <Badge>Vegan Menu</Badge> */}
      {/* {vegan.map((burger) => (
        <BurgerListItemVegan burger={burger} key={burger.name} />
      ))} */}
    </Wrapper>
  );
};

export default BurgerList;
