
import Heading from "../Heading/Heading";
import MenuScroll from "../MenuScroll/MenuScroll";
import SniadankoList from "./SniadankoList"
import BannerLink from "../BannerLink/BannerLink";
import Footer from "../Footer/Footer";
import KawaList from "./KawaList"

const Sniadanko = () => {
    return (
        <>
        <Heading>śniadanko</Heading>
        <MenuScroll highlight="sniadanko"/>
        <BannerLink link="/napoje">+ dobierz napój</BannerLink>
        <SniadankoList/>
        <KawaList/>
        <Footer/>
        </>
    );
};

export default Sniadanko;