import styled from "styled-components";

const Badge = styled.a`
    display:inline-flex;
    justify-content: center;
    align-items: center;
    font-size:1.8rem ;
    padding:0.2em 0.6em;
    border-radius:2.4rem ;
    font-family:'Gotham', sans-serif;
    color:${props => props.secondary ? "#737373" : "black"};
    background-color:${props => props.secondary ? "#dadada" : "#afca0b"};
    text-transform:uppercase ;
    cursor: pointer;
    text-decoration: none;
`;

export default Badge;