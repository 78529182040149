export const burgery = [
  // {
  //   name: "MANGOCZI",
  //   ingredients: [
  //     "100% wołowiny/kura/wege/seruś",
  //     "majo-sriracha",
  //     "świeży szpinak",
  //     "ser cheddar",
  //     "sos mango-chili",
  //     "czerwona cebula",
  //     "kolendra"
  //   ],
  //   img: "https://pasibus.blob.core.windows.net/media/6782/burger-of-monthczerwiec.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Standard",
    ingredients: [
      "100% wołowiny",
      "sos biały",
      "sałata lodowa",
      "ser cheddar",
      "pomidor",
      "ogórek kiszony",
      "cebula",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3342/standard_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Bebek Junior",
    ingredients: [
      "100% wołowiny",
      "sos różowy",
      "sałata lodowa",
      "autorski sos BBQ",
      "ser cheddar",
      "cebula",
      "grillowany boczek",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3337/bebek-jr_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Włoski Pastuch",
    ingredients: [
      "100% wołowiny",
      "sos biały",
      "rukola",
      "ser długo dojrzewający",
      "sos pomidorowo-bazyliowy",
      "grillowany boczek",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3343/w%C5%82oski-pastuch_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Gonzales",
    ingredients: [
      "100% wołowiny",
      "sos różowy",
      "rukola",
      "pasta red curry",
      "ser cheddar",
      "jalapeño",
      "nachosy",
    ],
    hot: 2,
    img: "https://pasibus.blob.core.windows.net/media/3735/gonzales_xl-1.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "Red Hot",
  //   ingredients: [
  //     "100% wołowiny",
  //     "sos jalapeño-majo",
  //     "sałata lodowa",
  //     "sos red hot z pepperoncino",
  //     "świeże chilli",
  //   ],
  //   hot: 3,
  //   img: "https://pasibus.blob.core.windows.net/media/3736/redhot_xl-1.png?width=500&mode=max&animationprocessmode=first",
  // },
  // {
  //   name: "Chorizard",
  //   ingredients: [
  //     "100% wołowiny",
  //     "sos różowy",
  //     "rukola",
  //     "pasta z suszonych pomidorów i żurawiny",
  //     "ser cheddar",
  //     "grillowane chorizo",
  //     "grillowane pieczarki",
  //   ],
  //   hot: 1,
  //   img: "https://pasibus.blob.core.windows.net/media/3734/chorizard_xl-1.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Awokadus",
    ingredients: [
      "100% wołowiny",
      "sos różowy",
      "roszponka",
      "autorskie guacamole",
      "sos musztardowo-miodowy z orzechami",
      "ser cheddar",
      "grillowany boczek",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3338/awokadus_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Biały Wilk",
    ingredients: [
      "100% wołowiny",
      "sos biały",
      "sałata lodowa",
      "duszona cebula",
      "podwójny grillowany boczek",
      "tymianek",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3339/bia%C5%82y-wilk_xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Poczwórny smash burger",
    ingredients: [
      "Poczwórna smashowana wołowina",
      "Poczwórny ser cheddar",
      "Sos różowy",
      "Duszona cebula",
      "Relish ogórkowy",
      "Sos BBQ",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6343/double-double-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const vegan = [
  // {
  //   name: "Standard Vegan",
  //   ingredients: [
  //     "Autorski kotlet warzywny",
  //     "wegański majonez",
  //     "sałata lodowa",
  //     "wegański ser cheddar",
  //     "pomidor",
  //     "ogórek kiszony",
  //     "cebula",
  //   ],
  //   img: "https://pasibus.blob.core.windows.net/media/3354/standard-vegan_xl.png?width=500&mode=max&animationprocessmode=first",
  // },
  // {
  //   name: "Cebulion Vegan",
  //   ingredients: [
  //     "Autorski kotlet warzywny",
  //     "wegański majonez",
  //     "rukola",
  //     "duszona cebula",
  //     "ogórek kiszony",
  //     "autorski sos BBQ",
  //   ],
  //   img: "https://pasibus.blob.core.windows.net/media/3353/cebulion-vegan_xl.png?width=500&mode=max&animationprocessmode=first",
  // },
];

export const tupak = [
  {
    name: "2pak Smash",
    ingredients: [
      "100% wołowiny",
      "sos różowy",
      "sałata lodowa",
      "ser cheddar",
      "ogórek kiszony",
      "frytki",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4423/group-1304426.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "2pak Właps",
    ingredients: [
      "autorski kurczak w panierce",
      "sos różowy",
      "sałata lodowa",
      "ser cheddar",
      "ogórek kiszony",
      "frytki",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4424/group-1304427.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "2pak Cziks",
    ingredients: [
      "autorski kurczak w panierce",
      "sos różowy",
      "sałata lodowa",
      "ser cheddar",
      "ogórek kiszony",
      "frytki",
    ],
    img: "https://pasibus.blob.core.windows.net/media/5115/2pak-cziks.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Smash",
    ingredients: [
      "100% wołowiny",
      "sos różowy",
      "sałata lodowa",
      "ser cheddar",
      "ogórek kiszony",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4422/group-1304425.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Właps",
    ingredients: [
      "autorski kurczak w panierce",
      "sos różowy",
      "sałata lodowa",
      "ser cheddar",
      "ogórek kiszony",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4425/group-1304428.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cziks",
    ingredients: [
      "autorski kurczak w panierce",
      "sos różowy",
      "sałata lodowa",
      "ser cheddar",
      "ogórek kiszony",
    ],
    img: "https://pasibus.blob.core.windows.net/media/5114/cziks.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const wypasik = [
  {
    name: "Double Pasik",
    ingredients: [
      "podwójny smash burger",
      "sos rózowy",
      "podwójny ser cheddar",
      "sałata lodowa",
      "kiszony ogórek",
      "pomidor",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4555/double-pasik-kiosk.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cheese Pasik",
    ingredients: [
      "100% wołowiny",
      "majonez",
      "sałata lodowa",
      "sos pomidorowo-bazyliowy",
      "ser cheddar",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3582/cheese-pasik.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Jalapeño Pasik",
    ingredients: [
      "100% wołowiny",
      "sos jalapeño-majo",
      "sałata lodowa",
      "jalapeño",
      "cebula",
    ],
    hot: 2,
    img: "https://pasibus.blob.core.windows.net/media/3584/jalapeno-pasik.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "BBQ Pasik",
    ingredients: [
      "100% wołowiny",
      "majonez",
      "sałata lodowa",
      "ser cheddar",
      "ogórek kiszony",
      "autorski sos BBQ",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3581/bbq-pasik.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const zestawy = [
  {
    name: "Full Pasik",
    ingredients: ["Dowolny Pasik", "Pasi Frytki", "Wybrany Napój"],
    img: "https://pasibus.blob.core.windows.net/media/3663/zestaw-full-wypasik.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Standard Zestaw",
    ingredients: ["Standard", "Pasi Frytki", "Wybrany Napój"],
    img: "https://pasibus.blob.core.windows.net/media/3861/standard-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Bebek Junior Zestaw",
    ingredients: ["Bebek Junior", "Pasi Frytki", "Wybrany Napój"],
    img: "https://pasibus.blob.core.windows.net/media/3855/bebek-junior-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Włoski Pastuch Zestaw",
    ingredients: ["Włoski Pastuch", "Pasi Frytki", "Wybrany Napój"],
    img: "https://pasibus.blob.core.windows.net/media/3859/pastuch-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Gonzales Zestaw",
    ingredients: ["Gonzales", "Pasi Frytki", "Wybrany Napój"],
    img: "https://pasibus.blob.core.windows.net/media/3858/gonzales-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "Red Hot Zestaw",
  //   ingredients: ["Red Hot", "Pasi Frytki", "Wybrany Napój"],
  //   img: "https://pasibus.blob.core.windows.net/media/3860/red-hot-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Awokadus Zestaw",
    ingredients: ["Awokadus", "Pasi Frytki", "Wybrany Napój"],
    img: "https://pasibus.blob.core.windows.net/media/3860/red-hot-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Chorizard Zestaw",
    ingredients: ["Chorizard", "Pasi Frytki", "Wybrany Napój"],
    img: "https://pasibus.blob.core.windows.net/media/3857/chorizard-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Biały Wilk Zestaw",
    ingredients: ["Biały Wilk", "Pasi Frytki", "Wybrany Napój"],
    img: "https://pasibus.blob.core.windows.net/media/3856/bia%C5%82y-wilk-pszenna-zestaw-pasidostawa-pl-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const dodatki = [
  {
    name: "Pasi Frytki Duże",
    description: ["Klasyczne frytki ze skórką."],
    img: "https://pasibus.blob.core.windows.net/media/3376/frytki_pude%C5%82ko_3.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi Frytki Średnie",
    description: ["Klasyczne frytki ze skórką."],
    img: "https://pasibus.blob.core.windows.net/media/3368/frytki_3.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi Frytki Małe",
    description: ["Klasyczne frytki ze skórką."],
    img: "https://pasibus.blob.core.windows.net/media/3377/frytki_7.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi Bataty",
    description: ["Grube, soczyste słodkie ziemniaki."],
    img: "https://pasibus.blob.core.windows.net/media/3384/bataty_2.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Kulki Serowe 8szt.",
    description: ["Klasyczne | Pikantne | Mix. Podane z sosem różówym!"],
    img: "https://pasibus.blob.core.windows.net/media/3418/kulki-serowe_mix_8szt.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Kulki Serowe 4szt.",
    description: ["Klasyczne | Pikantne | Mix. Podane z sosem różówym!"],
    img: "https://pasibus.blob.core.windows.net/media/3416/kulki-serowe_mix_4szt.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi Nachosy",
    description: ["Lekko pikantne, podawane z sosem różowym."],
    img: "https://pasibus.blob.core.windows.net/media/3374/nachosy_pude%C5%82ko_3.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Zielsko",
    description: ["Zielenina z autorskim dressingiem."],
    img: "https://pasibus.blob.core.windows.net/media/3372/zielsko_2.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Extra Sos",
    description: [
      "Porcja sosu do wyboru: biały, różowy, BBQ, redhot, jalapeño majo",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3593/dip-r%C3%B3%C5%BCowy.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Zestaw 3 Sosów",
    description: [
      "Wybierz 3 dowolne sosy: biały, różowy, BBQ, redhot, jalapeño majo",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3661/zestaw-3-sos%C3%B3w.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const sniadanko = [
  {
    name: "Boczuś",
    ingredients: [
      "jajecznica",
      "majonez",
      "ser cheddar",
      "grillowany boczek",
      "pieczarki grillowane",
      "szczypior",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4199/boczus.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Świeżak",
    ingredients: [
      "jajo sadzone",
      "sos biały",
      "rukola",
      "świeży pomidor",
      "szczypior",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4200/swiezak.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Boczuś Włap",
    ingredients: [
      "jajecznica",
      "majonez",
      "ser cheddar",
      "grillowany boczek",
      "pieczarki grillowane",
      "szczypior",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4202/boczus-wlap.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Świeżak Włap",
    ingredients: [
      "jajecznica",
      "sos różowy",
      "sałata lodowa",
      "pomidor",
      "ser cheddar",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4201/swiezak-wlap.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const salatki = [
  {
    name: "z kurczakiem",
    ingredients: [
      "Kurczak w chrupiącej panierce",
      "mix sałat z vinegrette",
      "pomidory koktajlowe",
      "ser długo dojrzewający",
      "prażony słonecznik i dynia",
      "sos musztardowo-miodowy z orzechami",
      "nachosy",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3650/top_sa%C5%82atka_kurczak.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "cesar",
    ingredients: [
      "Kurczak w chrupiącej panierce",
      "autorski sos cezar",
      "sałata rzymska",
      "ser grana padano",
      "grillowany boczek",
      "grzanki ziołowo-czosnkowe",
      "szczypiorek",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6342/sa%C5%82atka_cezar.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "z wege kurczakiem",
  //   ingredients: [
  //     "kurczak wege w autorskiej panierce",
  //     "mix sałat z vinegrette",
  //     "pomidory koktajlowe",
  //     "ser długo dojrzewający",
  //     "prażony słonecznik i dynia",
  //     "sos musztardowo-miodowy z orzechami",
  //     "nachosy",
  //   ],
  //   img: "https://pasibus.blob.core.windows.net/media/3649/top_sa%C5%82atka_kurczak-wege.png?width=500&mode=max&animationprocessmode=first",
  // },
];

export const kawa = [
  {
    name: "Cappuccino",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3683/kawa-z-piank%C4%85-2.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Latte",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3686/kawa-z-piank%C4%85-1.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Espresso",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3710/espresso2.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Americano",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3681/kawa-czarna.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Flat White",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3680/kawa-z-piank%C4%85-3.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Herbata",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3685/herbata.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const lemoIce = [
  {
    name: "Pasi Lemoniada",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3737/lemoniada-_-szklanka-du%C5%BCa.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pasi IceTea",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3635/icetea.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const napoje = [
  {
    name: "Coca-Cola",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3505/cola.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Coca-Cola Zero",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3496/cola-zero.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Fanta",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3492/fanta.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Sprite",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3500/sprite.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "Red Bull",
  //   ingredients: [""],
  //   img: "https://pasibus.blob.core.windows.net/media/3596/red-bull.png?width=500&mode=max&animationprocessmode=first",
  // },
  // {
  //   name: "Red Bull Zero",
  //   ingredients: [""],
  //   img: "https://pasibus.blob.core.windows.net/media/3595/red-bull-sugar-free.png?width=500&mode=max&animationprocessmode=first",
  // },
  {
    name: "Dobry Materiał",
    ingredients: [
      "gruszka, rabarbar, śliwka, mango, yerba mate, imbir, agreston",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3774/dobry-materia%C5%82.jpg?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Woda Gazowana",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3488/%C5%BCywiec-gaz.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Woda Niegazowana",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3507/butelka-%C5%BCywiec.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Żywiec Naturals",
    ingredients: ["Niegazowana woda o smakach: limonka-mięta, malina-cytryna"],
    img: "https://pasibus.blob.core.windows.net/media/3509/butelka-%C5%BCywiec-cytryna-malina.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Żywiec Smakowy",
    ingredients: [
      "Gazowana woda o smakach: cytryna-mięta, pomarańcza-grejpfrut",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3487/butelka-%C5%BCywiec-cytryna-mi%C4%99ta.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Soczek Cappy",
    ingredients: ["jabłkowy, pomarańczowy"],
    img: "https://pasibus.blob.core.windows.net/media/3498/cappy-pomara%C5%84cza.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const boxy = [
  {
    name: "DuŻy box",
    ingredients: ["3x dowolny pasik, duża ilość frytek"],
    img: "https://pasibus.blob.core.windows.net/media/3666/du%C5%BCy-box.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Giga box",
    ingredients: ["6x dowolny pasik, giga ilość frytek"],
    img: "https://pasibus.blob.core.windows.net/media/3665/giga-box.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const doladowanie = [
  {
    name: "Wołowina",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3558/kotlet-wo%C5%82owy-xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Kura",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3556/kurczak.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Wege",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3567/kotlet-wege-xl.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Seruś",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3559/kotlet-serowy.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Boczek",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3776/boczek.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cheddar",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3775/cheddar.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Chilli",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3555/chilli.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Jalapeño",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3570/jalapeno.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Jajo Sadzone",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3571/jajko.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Grillowane Pieczarki",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3557/pieczarki.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Chorizo",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3565/chorizo.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Nachosy",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3561/nachosy.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Parmezan",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3586/parmezan.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Cebula",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3562/cebula.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Ogórek Kiszony",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3560/og%C3%B3rek.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Pomidor",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3563/pomidor.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Guacamole",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3566/guacamole.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Sos biały",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3592/dip-bia%C5%82y.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Sos różowy",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3593/dip-r%C3%B3%C5%BCowy.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Sałata lodowa",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3637/sa%C5%82ata-lodowa.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Rukola",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3638/rukola.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Roszponka",
    ingredients: [""],
    img: "https://pasibus.blob.core.windows.net/media/3639/roszponka.png?width=500&mode=max&animationprocessmode=first",
  },
];

export const wlapy = [
  {
    name: "Standard",
    ingredients: [
      "kurczak w autorskiej panierce",
      "ser cheddar",
      "sos różowy",
      "sałata lodowa",
      "świeży pomidor",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3878/standart-w%C5%82ap-solo.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Gonzo",
    ingredients: [
      "kurczak w autorskiej panierce",
      "sos majo-jalapeño",
      "ser cheddar",
      "kruszone nachosy",
      "jalapeño",
      "cebula biała",
    ],
    img: "https://pasibus.blob.core.windows.net/media/3874/gonzo-w%C5%82ap-solo.png?width=500&mode=max&animationprocessmode=first",
  },
  // {
  //   name: "Bebek",
  //   ingredients: [
  //     "kurczak w autorskiej panierce",
  //     "ser cheddar",
  //     "sos BBQ",
  //     "duszona cebula",
  //     "ogórek kiszony",
  //   ],
  //   img: "https://pasibus.blob.core.windows.net/media/3870/bebek-w%C5%82ap-solo.png?width=500&mode=max&animationprocessmode=first",
  // },
];

export const wlapson = [
  {
    name: "Włapson Różowy",
    ingredients: [
      "kurczak w autorskiej panierce",
      "ser cheddar",
      "sos różowy",
      "sałata lodowa",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4050/w%C5%82apson-r%C3%B3%C5%BCowy-full-zestaw-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "Włapson BBQ",
    ingredients: [
      "kurczak w autorskiej panierce",
      "ser cheddar",
      "sos bbq-majo",
      "sałata lodowa",
    ],
    img: "https://pasibus.blob.core.windows.net/media/4051/w%C5%82apson-bbq_mayo-full-zestaw-1000x1000.png?width=500&mode=max&animationprocessmode=first",
  },
];

// LTO 1 - MAC & CHEESE
// export const special = [
//   {
//     name: "MAC&CHEESE BURGER",
//     ingredients: [
//       "100% wołowiny | kura | wege | ser seruś",
//       "sos różowy",
//       "grillowany boczek",
//       "ser mimolette",
//       "piklowana czerwona cebula",
//       "szczypiorek",
//       "Mac&Cheese",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5887/lto1_mc_burger.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE PODWÓJNY BURGER",
//     ingredients: [
//       "100% wołowiny | kura | wege | ser seruś",
//       "sos różowy",
//       "grillowany boczek",
//       "ser mimolette",
//       "piklowana czerwona cebula",
//       "szczypiorek",
//       "Mac&Cheese",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5890/lto1_mc_burger_double.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE CHILI BURGER",
//     ingredients: [
//       "100% wołowiny | kura | wege | ser seruś",
//       "sos różowy",
//       "grillowany boczek",
//       "ser mimolette",
//       "piklowana czerwona cebula",
//       "szczypiorek",
//       "Mac&Cheese",
//       "prażone płatki chili",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5891/lto1_mc_burger_chili.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE CHILI PODWÓJNY BURGER",
//     ingredients: [
//       "100% wołowiny | kura | wege | ser seruś",
//       "sos różowy",
//       "grillowany boczek",
//       "ser mimolette",
//       "piklowana czerwona cebula",
//       "szczypiorek",
//       "Mac&Cheese",
//       "prażone płatki chili",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5889/lto1_mc_burger_double_chili.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE WŁAP",
//     ingredients: [
//       "Kurczak w autorskiej panierce",
//       "sos różowy",
//       "grillowany boczek",
//       "piklowana czerwona cebula",
//       "szczypiorek",
//       "Mac&Cheese",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/5888/lto1_wlap.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "MAC&CHEESE KUBEK",
//     ingredients: ["Makaron w sosie serowym", "świeży szczypiorek", "pieprz"],
//     img: "https://pasibus.blob.core.windows.net/media/5892/lto1_kubelek.png?width=500&mode=max&animationprocessmode=first",
//   },
// ];

// LTO 2 - SZARPAK
// export const special = [
//   {
//     name: "PULLED BEEF BURGER",
//     ingredients: [
//       "100% wołowiny | kura | wege | ser seruś",
//       "szarpana wołowina w sosie demi glace",
//       "piklowana czerwona kapusta",
//       "podwójny ser cheddar",
//       "sos majo-BBQ",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6219/pb-poj.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF PODWÓJNY BURGER",
//     ingredients: [
//       "100% wołowiny | kura | wege | ser seruś",
//       "szarpana wołowina w sosie demi glace",
//       "piklowana czerwona kapusta",
//       "podwójny ser cheddar",
//       "sos majo-BBQ",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6218/pb-podw.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF PIKANTNY BURGER",
//     ingredients: [
//       "100% wołowiny | kura | wege | ser seruś",
//       "szarpana wołowina w sosie demi glace",
//       "piklowana czerwona kapusta",
//       "podwójny ser cheddar",
//       "sos majo-BBQ",
//       "jalapeno",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6217/pb-pik-poj.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF PIKANTNY PODWÓJNY BURGER",
//     ingredients: [
//       "100% wołowiny | kura | wege | ser seruś",
//       "szarpana wołowina w sosie demi glace",
//       "piklowana czerwona kapusta",
//       "podwójny ser cheddar",
//       "sos majo-BBQ",
//       "jalapeno",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6216/pb-pik-podw.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF WŁAP",
//     ingredients: [
//       "100% szarpanej wołowiny w sosie demi glace",
//       "piklowana czerwona kapusta",
//       "podwójny ser cheddar",
//       "sos majo-BBQ",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6220/pb-wlap.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF FRYTKI",
//     ingredients: [
//       "Pasi Frytki",
//       "100% szarpanej wołowiny w sosie demi glace",
//       "sos majo-BBQ",
//       "szczypiorek",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6215/pb-frytki.png?width=500&mode=max&animationprocessmode=first",
//   },
//   {
//     name: "PULLED BEEF FRYTKI PIKANTNE",
//     ingredients: [
//       "Pasi Frytki",
//       "100% szarpanej wołowiny w sosie demi glace",
//       "sos majo-BBQ",
//       "szczypiorek",
//       "jalapeno",
//     ],
//     img: "https://pasibus.blob.core.windows.net/media/6214/pb-frytki-pik.png?width=500&mode=max&animationprocessmode=first",
//   },
// ];

// LTO 3 - CHILI CON CARNE
export const special = [
  {
    name: "AMIGO BURGER",
    ingredients: [
      "100% wołowiny | kura | wege | ser seruś",
      "kwaśna śmietana",
      "Chili con carne",
      "nachosy",
      "kolendra",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6613/amigo.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "AMIGO BURGER PODWÓJNY",
    ingredients: [
      "100% wołowiny | kura | wege | ser seruś",
      "kwaśna śmietana",
      "Chili con carne",
      "nachosy",
      "kolendra",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6605/amigo-podwo-jny.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "MACHO BURGER",
    ingredients: [
      "100% wołowiny | kura | wege | ser seruś",
      "kwaśna śmietana",
      "Chili con carne",
      "salsa ogórek z mango",
      "nachosy",
      "guacamole",
      "kolendra",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6612/macho.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "PODWÓJNY MACHO BURGER",
    ingredients: [
      "100% wołowiny | kura | wege | ser seruś",
      "kwaśna śmietana",
      "Chili con carne",
      "salsa ogórek z mango",
      "nachosy",
      "guacamole",
      "kolendra",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6611/macho-podwo-jny.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "CHICA WŁAP",
    ingredients: [
      "Chili con carne",
      "sałata lodowa",
      "kwaśna śmietana",
      "ser cheddar",
      "nachosy",
      "kolendra",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6607/chica-w%C5%82ap.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "NACHOS CON CARNE",
    ingredients: [
      "Chili con carne",
      "nachosy",
      "kwaśna śmietana",
      "pieczone papryczki padron",
      "salsa ogórek z mango",
      "kolendra",
    ],
    img: "https://pasibus.blob.core.windows.net/media/6608/nachos-con-carne.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "MANGO LEMO",
    ingredients: ["Lemoniada mango"],
    img: "https://pasibus.blob.core.windows.net/media/6609/mango-lemo.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "MANGO CHILI LEMO",
    ingredients: ["Lemoniada mango z chili"],
    img: "https://pasibus.blob.core.windows.net/media/6610/mango-lemo-chili.png?width=500&mode=max&animationprocessmode=first",
  },
  {
    name: "SALSA OGÓREK Z MANGO",
    ingredients: ["Salsa z ogórka z dodatkiem mango i kolendry"],
    img: "https://pasibus.blob.core.windows.net/media/6606/salsa-ogo-rek-mango.png?width=500&mode=max&animationprocessmode=first",
  },
];
